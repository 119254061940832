import React from 'react';
import '../Project.scss';
import ProjectModule from '../components/ProjectModule';


export default class Films extends React.Component {

  render() {

    let data = this.props.data
    let filmsData = []

    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let itemType = data[i].fields.Type
        if (itemType.includes("Video") && data[i].fields["Display on Website?"] === true) {
          filmsData.push(data[i])
        }
      }
      // assign them a date if they don't have one, then sort by date
      filmsData.map(( film, i ) => { return film.fields.Date ? null : film.fields.Date = "2000-01-01" })
      filmsData.sort(function(b,a){ return a.fields.Date.localeCompare(b.fields.Date);})
      // console.log("found films...", filmsData);
   } else {
      console.log("no data found")
    }

    return (

        <div id="films" className="projectDashboard">
        {filmsData.length > 0 ?
            filmsData.map(( film, i ) => {
              return ( <ProjectModule data={film} key={i} /> )
            })
            
            :

          <div className="loading">
            LOADING...
          </div>
        }
      </div>
      
    )
  }
}
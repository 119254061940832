import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

// images
import wpLogo from '../assets/img/WP_logo_large.gif';

// css
import '../App.scss';

function Menu() {

  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggle = () => {
    setMenuOpen(!menuOpen)
    // console.log("menu open", menuOpen)
  }

    return (

      <div>
      {/*{console.log(menuOpen)}*/}

        { window.innerWidth > 800 || menuOpen ?
          <div className="menu">
            <a href="/">
              <img className="wp-logo" src={wpLogo} alt="hand-drawn logo for wastepaper opera"/>
            </a>
            <div className="menu-heading-container">
              <div id="menuClose" onClick={handleToggle}>
                X
              </div>
              <div className="menu-heading news" >
                <NavLink to="/" onClick={handleToggle}>News</NavLink>
              </div>
              <div className="menu-heading about" >
                <NavLink to="about" onClick={handleToggle}>About</NavLink>
              </div>
              <div className="menu-heading works" >
                <NavLink to="works" onClick={handleToggle}>Works</NavLink>
              </div>
{/*              <div className="menu-heading calendar" >
                <NavLink to="calendar" onClick={handleToggle}>Calendar</NavLink>
              </div>*/}
              <div className="menu-heading curation" >
                <NavLink to="curation" onClick={handleToggle}>Curation</NavLink>
              </div>
              <div className="menu-heading films" >
                <NavLink to="films" onClick={handleToggle}>Film</NavLink>
              </div>
              <div className="menu-heading engagement" >
                <NavLink to="engagement" onClick={handleToggle}>Engagement</NavLink>
              </div>
              <div className="menu-heading contact" >
                <NavLink to="contact" onClick={handleToggle}>Contact</NavLink>
              </div>
            </div>
          </div>

          :

          <div id="menuButton" className="menu-heading" onClick={handleToggle}>
            ___
          </div>
        }
      </div>

    );
  }

export default Menu;


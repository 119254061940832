import React from 'react';
import { useNavigate} from "react-router-dom";

function Back() {

    let navigate = useNavigate();

    return (
          <div id="backButton" onClick={() => navigate(-1)}><a>go back</a></div> 
    );  
  }

export default Back;
import React from 'react';
import '../Project.scss';
import ReactMarkdown from 'react-markdown';

export default class News extends React.Component {

  render() {

    let data = this.props.data
    let newsData = []

    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let itemType = data[i].fields.Type
        if (itemType.includes("News") && data[i].fields.Date && data[i].fields["Display on Website?"] === true) {
          newsData.push(data[i])
        }
        // sort by "Date"
        newsData.sort(function(b,a){
          return a.fields.Date.localeCompare(b.fields.Date);
        }) 
      }
      // console.log("found news...", newsData)
    } else {
      console.log("no data found")
    }

    return (
      <div className="project" id="news">
        <div className="projectColumn">
          {newsData.length > 0 ?

            newsData.map(( news, i ) => {
              return (
                <div key={i}>
                    {/*<div className="title">
                      {news.fields.Date}
                    </div>   */}             
                    <div className="title">
                      {news.fields.Name}
                    </div>
                    <div className="projectDescription">
                      <ReactMarkdown>
                        {news.fields["Long Description"]}
                      </ReactMarkdown>
                    </div>
                    <div>
                      <img src={news.fields["Cover Image"] && news.fields["Cover Image"][0].thumbnails.large.url } />
                    </div>
                    <div id="separator">~</div>
                  </div>   
              )

            })

            :

            <div className="loading">
              LOADING...
            </div>
          }
        </div>
      </div>
        

    )
  }
}

import React from 'react';
import '../Project.scss';

export default class Contact extends React.Component {

  render() {

    let data = this.props.data
    let contactsData = []

    if (data.length > 0) {
      // console.log("found data...")
      for (let i = 0; i < data.length; i++) {
        let itemType = data[i].fields.Type
        if (itemType.includes("Contact")) {
          contactsData.push(data[i])
        }
      }
      // console.log("found contact...", contactsData)
    } else {
      console.log("no data found")
    }

    return (

      <div className="project" id="contact">
        <div className="title">
          Get in touch
        </div>            
        {contactsData.length > 0 ?

          contactsData.map(( contact, i ) => {
            return (
              <div key={i}>
                <a href={contact.fields.URL}>
                  {contact.fields.Name}
                </a>
              </div>


              )
          })
          
          :

          <div className="loading">
            LOADING...
          </div>
        }
      </div>
      
    )
  }
}


// { data.CONTACT.records.map(( contact, i ) => {
//     return (
//         <a href={contact.fields.Link} key={i}> <span className="project-button">{contact.fields.Medium}</span></a>
//       )
//   }
// )}
import React from 'react';
import '../Project.scss';
import ReactMarkdown from 'react-markdown';

export default class Calendar extends React.Component {

  render() {

    let data = this.props.data
    let calendarData = []

    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let itemType = data[i].fields.Type
        if (itemType.includes("Calendar") && data[i].fields.Date && data[i].fields["Display on Website?"] === true) {
          calendarData.push(data[i])
        }
        // sort by "Date"
        calendarData.sort(function(b,a){
          return a.fields.Date.localeCompare(b.fields.Date);
        }) 
      }
      // console.log("found calendar items...", calendarData)
    } else {
      console.log("no data found")
    }

    return (
      <div className="project news">
        {calendarData.length > 0 ?

          calendarData.map(( calendarItem, i ) => {
            return (
              <div key={i}>
                <div className="title">
                  {calendarItem.fields.Name &&
                    calendarItem.fields.Name}
                </div>
                <div className="projectColumn">

                  <div>
                    <img src={calendarItem.fields["Cover Image"] && calendarItem.fields["Cover Image"][0].thumbnails.large.url} />
                  </div>
                  <div className="projectDescription">
                    <ReactMarkdown>
                      {calendarItem.fields["Long Description"] && calendarItem.fields["Long Description"]}
                  </ReactMarkdown>
                  </div>
                </div>   
              </div>
            )

          })

          :

          <div className="loading">
            LOADING...
          </div>
        }
      </div>
    )
  }
}


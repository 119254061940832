import React from 'react';
import '../Project.scss';
import ProjectModule from '../components/ProjectModule';


export default class Works extends React.Component {

  render() {

    let data = this.props.data
    let worksData = []

    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let itemType = data[i].fields.Type
        if (itemType.includes("Main Work") && data[i].fields["Display on Website?"] === true) {
          worksData.push(data[i])
        }
      }
      // assign them a date if they don't have one, then sort by date
      worksData.map(( work, i ) => { return work.fields.Date ? null : work.fields.Date = "2000-01-01" })
      worksData.sort(function(b,a){ return a.fields.Date.localeCompare(b.fields.Date);})
      // console.log("found operas...", worksData)
    } else {
      console.log("no data found")
    }

    return (

      <div id="works" className="projectDashboard">
        {worksData.length > 0 ?
            worksData.map(( work, i ) => {
              return ( <ProjectModule data={work} key={i} /> )
            })
            :
          <div className="loading">
            LOADING...
          </div>
        }
      </div>
    )
  }
}
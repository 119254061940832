import React from 'react';
import '../App.scss';
import ReactMarkdown from 'react-markdown';
import Back from '../components/Back';

export default class About extends React.Component{

render () {

  let data = this.props.data
  let aboutData = []
  let bioData = []

  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      let itemType = data[i].fields.Type
      if (itemType.includes("About Page") && data[i].fields["Display on Website?"] === true) {
        aboutData.push(data[i])
      }
      if (itemType.includes("Bio")) {
        bioData.push(data[i])
      }
    }
      // console.log("found about us...", aboutData)
      // console.log("found bios...", bioData)
  } else {
    console.log("no data found")
  }

  return (
    <div className="project" id="about">
      {aboutData.length > 0 ?
        
        <div>
        <Back/>
          <div className="projectTitle">
            {aboutData[0].fields.Name}
          </div>
          <div className="projectColumn">
            <div>
              <img src={aboutData[0].fields["Cover Image"][0].thumbnails.large.url} />
            </div>
            <div className="projectDescription">
              <ReactMarkdown>
                {aboutData[0].fields["Long Description"]}
              </ReactMarkdown>
            </div>

            {bioData.length > 0 ?
              <div id="bios">
                <div className="projectTitle">
                  Team
                </div>
                {bioData.map(( bio, i ) => {
                  return ( 
                    <div key={i}>
                      <div className="title">
                        {bio.fields.Name}
                      </div>
                      <div>
                        <img src={bio.fields.Headshot[0] && bio.fields.Headshot[0].thumbnails.large.url} />
                      </div>
                      <div>
                        <ReactMarkdown>
                          {bio.fields["Short Blurb"]}
                        </ReactMarkdown>
                      </div>
                      <div>
                        <ReactMarkdown>
                          {bio.fields["Long Description"]}
                        </ReactMarkdown>
                      </div>
                    </div>
                  )}
                )}
              </div>
              :
              <div>
                no bios
              </div>
            }

          </div>
        </div> 

        
        :

        <div className="loading">
          LOADING...
        </div>

      }
    </div>

      
    )
  }
}
import React from 'react';
import '../Project.scss';
import ProjectModule from '../components/ProjectModule';

export default class Curation extends React.Component {

  render() {

    let data = this.props.data
    let curationData = []

    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let itemType = data[i].fields.Type
        if (itemType.includes("Curatorial Project") && data[i].fields["Display on Website?"] === true) {
          curationData.push(data[i])
        }
      }
      // assign them a date if they don't have one, then sort by date
      curationData.map(( curation, i ) => { return curation.fields.Date ? null : curation.fields.Date = "2000-01-01" })
      curationData.sort(function(b,a){ return a.fields.Date.localeCompare(b.fields.Date);})
      // console.log("found curatorial projects...", curationData)
    } else {
      console.log("no data found")
    }

    return (

       <div id="curatorial" className="projectDashboard">
        {curationData.length > 0 ?
            curationData.map(( curation, i ) => {
              return ( 
                  <ProjectModule data={curation} key={i} />
              )
            })
          
          :

          <div className="loading">
            LOADING...
          </div>
        }
      </div>
      
    )
  }
}
import React from 'react';
import '../Project.scss';
import ProjectModule from '../components/ProjectModule';

export default class Engagements extends React.Component {

  render() {

    let data = this.props.data
    let engagementsData = []

    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let itemType = data[i].fields.Type
        if (itemType.includes("Engagement") && data[i].fields["Display on Website?"] === true) {
          engagementsData.push(data[i])
        }
      }
      // assign them a date if they don't have one, then sort by date
      engagementsData.map(( engagement, i ) => { return engagement.fields.Date ? null : engagement.fields.Date = "2000-01-01" })
      engagementsData.sort(function(b,a){ return a.fields.Date.localeCompare(b.fields.Date);})
      // console.log("found engagement projects...", engagementsData)
    } else {
      console.log("no data found")
    }

    return (

       
       <div id="engagement" className="projectDashboard">
        {engagementsData.length > 0 ?
            engagementsData.map(( engagement, i ) => {
              return ( <ProjectModule data={engagement} key={i} /> )
            })
            
            :

          <div className="loading">
            LOADING...
          </div>
        }
      </div>
      
    )
  }
}

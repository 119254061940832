import React from 'react';
import '../Project.scss';
import ReactMarkdown from 'react-markdown';
import Back from '../components/Back';

export default class ProjectPage extends React.Component {
  
   constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {

    let projectData = ""
    let projectImages = ""
    let projectImageArr = ""
    let projectVideo = ""
    let data = this.props.data

    if (data.length > 0) {
      let slug = window.location.pathname.substring(1) // remove the slash to find a match with airtable slugs
      projectData = data.filter(datum => datum.fields.URLslug === slug)
      // console.log("we're on a project page...", slug)

      // check images for undefined (airtable issue?)
      if (projectData[0].fields.Images !== undefined) {
        // console.log(projectData)
        // projectData.length > 0 ? console.log(projectData[0].fields.Images.length + " images found!") : console.log("no images found; image array was 0")
        projectImageArr = projectData[0].fields.Images.reverse()
        // console.log("checking images...")
        projectImages = projectImageArr.filter( suspects => suspects.thumbnails !== undefined)
        // console.log(projectImages.length + " images found, " + (projectImageArr.length - projectImages.length) + " images excluded" )
        // projectImages.map(( image, i ) => { console.log(image.thumbnails) })
        projectVideo = projectData[0].fields["Video Embed Link"]
      } else {
        // console.log("No project images :(, moving on...")
        projectVideo = projectData[0].fields["Video Embed Link"]
      }      
    } else {
      console.log("no data found :(")
    }
    
    return (

      <div className="project">

        {projectData !== "" ?

          <div className="projectContainer">
          <Back />
                <div className="projectTitle" >
                  {projectData[0].fields.Name && projectData[0].fields.Name}
                </div>
                <div className="projectColumns">
                  <div className="Verso">                
                    <div className="projectBlurb">
                      {projectData[0].fields["Short Blurb"] && projectData[0].fields["Short Blurb"]}
                    </div>
                    <div className="projectDescription">
                      <ReactMarkdown>
                        {projectData[0].fields["Long Description"] && projectData[0].fields["Long Description"]}
                      </ReactMarkdown>
                    </div>
                      {projectVideo &&
                        <div className="projectVideo">
                          <iframe src={projectVideo} />
                        </div>
                      }
                    </div>
                    <div className="Recto">
                    {projectImages.length > 0 ?
                      <div className="projectImages">
                        {
                          projectImages.map(( projectImage, i ) => {
                            return <img key={i} src={projectImage.thumbnails.large.url} alt={"image of " + projectData[0].fields.Name + " by Waste Paper Opera"} />
                          })}
                      </div>
                      :
                      null
                    }
                    </div>

                  
              </div>
            </div>

          :

          <div className="loading">Loading project...</div>

        }

      </div>

    ) 
  }
}

import React from 'react';
import '../Project.scss';
import { NavLink } from 'react-router-dom';

export default class ProjectModule extends React.Component {

  render() {

    let data = this.props.data

    return (

      data &&
        <NavLink to={"/" + data.fields.URLslug}>
          <div className="projectModule">
            <img src={data.fields["Cover Image"][0].thumbnails.large.url} alt={"image of " + data.fields.Name + " by Waste Paper Opera"} />
            <div className="title">
              {data.fields.Name}
            </div>
            <div className="blurb title">
                {data.fields["Short Blurb"]}
            </div>
          </div>      
        </NavLink>
      
    
    )
  }
}


import React, { useState, useEffect, useRef } from 'react';

import Menu from './components/Menu';
import Contact from './components/Contact';
import Curation from './components/Curation';
import Calendar from './components/Calendar';
import About from './components/About';
import Films from './components/Films';
import News from './components/News';
import Projects from './components/Projects';
import Engagement from './components/Engagement';
import ProjectPage from './components/ProjectPage';

import { BrowserRouter, Route, Routes} from "react-router-dom";
import './App.scss';

function App() {

  const airtableDataRef = useRef([])

  // Pagination
  // const [currentPage, setCurrentPage] = useState(0)
  // const [numPages, setNumPages] = useState(1)
  // const [entries, setEntries] = useState([]);
  // const PER_PAGE = 100 // to make things simpler, lets stick to the max number of records we receive from Airtable, which is 100
  // const paginationOffset = currentPage * PER_PAGE

  // Component for receiving all the airtable data

  const [allWPO, setAllWPO] = useState([]);

  // Airtable API
  const PAT = process.env.REACT_APP_AIRTABLE_PAT
  const airtableApiKey = process.env.REACT_APP_AIRTABLE_KEY
  const airtableBase = process.env.REACT_APP_AIRTABLE_BASE
  const airtableTable = process.env.REACT_APP_AIRTABLE_TABLE
  const airtableUrl = process.env.REACT_APP_AIRTABLE_URL
  const [airtableOffset, setAirtableOffset] = useState('')
  const request = `${airtableUrl}/${airtableBase}/${airtableTable}`

  const fetchData = async (request) => {
    const res = await fetch(request, {
      method: 'GET',
      "headers": { "Authorization": `Bearer ${PAT}` },
      'content-type': 'application/json'
    });
    const data = await res.json();
    console.log(PAT)
    return data
  }

  // Fetch table: Works
  useEffect(() => {
    fetchData(request)
      .then(data => {
        const { records, offset } = data
        console.log(request, "AllWPO:", records)

        if (!records) return

        if (offset) {
          setAirtableOffset(offset)
          fetchData(request)
            .then(_data => {
              airtableDataRef.current.push(_data.records)
            })
        } else {
          airtableDataRef.current.push(records)
        }
        // setNumPages(airtableDataRef.current.length)
        setAllWPO(airtableDataRef.current[0])
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airtableOffset])


  return (
  <div className="App">

  <BrowserRouter>
    <Menu />
    <div className="main">
      
      <Routes>
        <Route path="/about" element={<About data={allWPO} />} />
        <Route path="/works" element={<Projects data={allWPO} />} />
        <Route path="/calendar" element={<Calendar data={allWPO} />} />
        <Route path="/curation" element={<Curation data={allWPO} />} />
        <Route path="/films" element={<Films data={allWPO} />} />
        <Route path="/engagement" element={<Engagement data={allWPO} />} />
        <Route path="/contact" element={<Contact data={allWPO} />} />
        <Route path="/" element={<News data={allWPO} />} />
        <Route path="/:id" element={<ProjectPage data={allWPO}/>} />
      </Routes>

    </div>
   
  </BrowserRouter>
  </div>

  );
}

export default App;
